<template>
	<v-container fluid>
		<v-row class="mb-12" justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/apofolio_logo.png" contain max-height="8vh" position="center right"></v-img>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6" align="center" justify="center">
				<h4 class="display-1 mb-3 secondary--text">#EFFIZIENT</h4>
				<h4 class="display-1 mb-3 primary--text">#WIRTSCHAFTLICH</h4>
				<h4 class="display-1 mb-3 accent--text">#TRANSPARENT</h4>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<h4 class="display-1 mb-3 primary--text">Unser Unternehmen</h4>
				<h5 class="headline mb-4">Die APOFOLIO Software und Service GmbH hat Ihren Sitz im Kölner Süden. Die Gesellschaft wird durch den geschäftsführenden Gesellschafter Christian Igel vertreten.</h5>
				<h5 class="headline mb-4">Die Gesellschaft hat folgende Tätigkeitsschwerpunkte:</h5>
				<ul class="headline mb-4">
					<li>die Entwicklung von Software für betriebswirtschaftliche und organisatorische Bereiche in Apotheken und das Management von Apotheken sowie deren Dienstleistern und Beratungsunternehmen</li>
					<li>der Vertrieb von eigenen Softwarelösungen im Bereich Betriebswirtschaft, Organisation und Management</li>
					<li>die Verarbeitung von Daten aus externen Datenquellen und die Bereitstellung von Daten über eigene Softwarelösungen</li>
					<li>die Bereitstellung von Dienstleistungen im Bereich Einkauf, Controlling und Management sowie Beratung von Apotheken zu den vorgenannten Themenbereichen</li>
				</ul>
				<h5 class="headline mb-4">Die APOFOLIO GmbH entwicklelt alle Softwarebausteine komplett selbst.</h5>
				<h5 class="headline mb-4">Insbesondere ist die APOFOLIO GmbH auf die Verarbeitung von Warenwirtschaftsdaten aus Apotheken spezialisiert und kann Daten von mehr als 90 Prozent der bundesdeutschen Apotheken verarbeiten.</h5>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	
}
</script>