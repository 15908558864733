<template>
	<v-container fluid>
		<v-row class="mb-12" justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/apofolio_logo.png" contain max-height="8vh" position="center right"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<h5 class="headline font-weight-medium mb-4">Wir freuen uns über die Nutzung unserer Webseite. Nachfolgend möchten wir Sie über den Umgang mit Ihren Daten gem. Art. 13 Datenschutzgrundverordnung (DSGVO) informieren.</h5>
				<h4 class="display-1 mb-3 primary--text">Verantwortlicher</h4>
				<h5 class="headline mb-4">Verantwortlich für die nachfolgend dargestellte Datenerhebung und Verarbeitung ist die im Impressum genannte Stelle.</h5>
				<h4 class="display-1 mb-3 primary--text">Nutzungsdaten</h4>
				<h5 class="headline mb-4">Bei der Nutzung unserer Webseite werden keine Daten zu statistischen Zwecken erhoben und gespeichert.</h5>
				<h4 class="display-1 mb-3 primary--text">Datensicherheit</h4>
				<h5 class="headline mb-4">Um Ihre Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir technische und organisatorische Maßnahmen. Wir setzen bei unserer Webseite ein Verschlüsselungsverfahren ein. Ihre Angaben werden von Ihrem Rechner zu unserem Server und umgekehrt über das Internet mittels einer TLS-Verschlüsselung übertragen. Sie erkennen dies daran, dass in der Statusleiste Ihres Browsers das Schloss-Symbol geschlossen ist und die Adresszeile mit https:// beginnt.</h5>
				<h4 class="display-1 mb-3 primary--text">Cookies</h4>
				<h5 class="headline mb-4">Innerhalb unserer Webseite nutzen wir keine Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert und ausgelesen werden können. Man unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald Sie ihren Browser schließen und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden. Cookies können Daten enthalten, die eine Wiedererkennung des genutzten Geräts möglich machen. Teilweise enthalten Cookies aber auch lediglich Informationen zu bestimmten Einstellungen, die nicht personenbeziehbar sind.
			Sie können Ihren Browser so einstellen, dass er Sie über die Platzierung von Cookies informiert. So wird der Gebrauch von Cookies für Sie transparent. Sie können Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen neuer Cookies verhindern.</h5>
				<h4 class="display-1 mb-3 primary--text">Datenübermittlung an Dritte</h4>
				<h5 class="headline mb-4">Wir behandeln Ihre personenbezogenen Daten vertraulich und übermitteln diese nicht an unbefugte Dritte.</h5>
				<h4 class="display-1 mb-3 primary--text">Auftragsverarbeitung</h4>
				<h5 class="headline mb-4">Im Rahmen des Betriebs dieser Webseite und der damit zusammenhängenden Prozesse können uns weitere Dienstleister unterstützen (z. B. beim Hosting und der Webentwicklung). Diese Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend Artikel 28 Datenschutzgrundverordnung vertraglich verpflichtet.</h5>
				<h4 class="display-1 mb-3 primary--text">Ihre Rechte als Nutzer</h4>
				<h5 class="headline font-weight-medium mb-4">Auskunftsrecht (Art. 15 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht, unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</h5>
				<h5 class="headline mb-4">Sie haben zudem das Recht, zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h5>
				<h5 class="headline mb-4">In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.</h5>
				<h5 class="headline font-weight-medium mb-4">Widerspruchsrecht (Art. 21 DSGVO)</h5>
				<h5 class="headline mb-4">Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung zur Wahrung berechtigter Interessen), steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</h5>
				<h4 class="display-1 mb-3 primary--text">Beschwerderecht bei einer Aufsichtsbehörde</h4>
				<h5 class="headline mb-4">Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</h5>
				<h4 class="display-1 mb-3 primary--text">Kontaktdaten des Datenschutzbeauftragten</h4>
				<h5 class="headline mb-4">Unser Datenschutzbeauftragter steht Ihnen gerne für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:</h5>
				<h5 class="headline">ApoTune Consulting GmbH</h5>
				<h5 class="headline">Häherweg 9</h5>
				<h5 class="headline mb-4">48291 Telgte</h5>
				<h5 class="headline">Ansprechpartner: Dr. Hendrik Hahn</h5>
				<h5 class="headline">Telefon: +49 173 219 21 56</h5>
				<h5 class="headline">E-Mail: hendrik.hahn@apotune-consulting.email</h5>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	
}
</script>