<template>
	<v-container fluid>
		<v-row class="mb-12" justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/apofolio_logo.png" contain max-height="8vh" position="center right"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<h4 class="display-1 mb-3 primary--text">DATENSCHUTZERKLÄRUNG FÜR UNSERE FACEBOOK SEITE</h4>
				<h5 class="headline font-weight-medium mb-4">Verantwortlicher für die Facebook Seite</h5>
				<h5 class="headline">APOFOLIO Software und Service GmbH</h5>
				<h5 class="headline">Stieleichenweg 2A</h5>
				<h5 class="headline mb-4">50999 Köln</h5>
				<h5 class="headline mb-4">Vertreten durch: Christian Igel (Geschäftsführender Gesellschafter)</h5>
				<h5 class="headline font-weight-medium mb-4">Nutzungsdaten und Datenschutz</h5>
				<h5 class="headline mb-4">Bei einem Besuch auf unserer Facebook Seite werden von Facebook als Verantwortlicher etwa über den Einsatz von Cookies personenbezogene Daten der Nutzer erhoben. Eine solche Datenerhebung durch Facebook kann auch bei Besuchern dieser Facebook Seite erfolgen, die nicht bei Facebook eingeloggt oder registriert sind. Informationen über die Datenerhebung und weitere Verarbeitung durch Facebook finden Sie in den Datenschutzhinweisen von Facebook.</h5>
				<h5 class="headline mb-4">Welche Nutzerdaten Facebook erfasst, kann die APOFOLIO Software und Service GmbH nicht nachvollziehen. Auch hat die APOFOLIO Software und Service GmbH keinen vollumfänglichen Zugriff auf die erfassten Daten oder Ihre Profildaten. Die APOFOLIO Software und Service GmbH kann nur die öffentlichen Informationen Ihres Profils sehen. Welche dies konkret sind, entscheiden Sie in Ihren Facebook Einstellungen. Darüber hinaus haben Sie in Ihren Facebook Einstellungen die Möglichkeit Ihre „Likes“ aktiv zu verbergen oder der Seite nicht mehr zu folgen. Dann erscheint Ihr Profil nicht mehr in der Liste der Fans dieser Facebook Seite.</h5>
				<h5 class="headline mb-4">Die APOFOLIO Software und Service GmbH erhält von Facebook anonyme Statistiken zur Verwendung und Nutzung der Seite. Folgende Informationen werden hier beispielsweise bereitgestellt:</h5>
				<h5 class="headline mb-4">Follower-Daten: Anzahl an Personen, die der APOFOLIO Software und Service GmbH folgen - inklusive Zuwachsraten und Entwicklung über einen definierten Zeitrahmen.</h5>
				<h5 class="headline mb-4">Reichweitendaten: Anzahl an Personen, die einen spezifischen Beitrag von uns ansehen. Anzahl der Interaktionen auf unseren Beitrag. Daraus lässt sich zum Beispiel ableiten, welche Relevanz der Beitragsinhalt in der Community im Vergleich zu unseren anderen Beiträgen hatte.</h5>
				<h5 class="headline mb-4">Daten zur Anzeigenperformance: Welche Kosten entstehen der APOFOLIO Software und Service GmbH bei einem Klick? Wie viele Personen haben eine Anzeige der APOFOLIO Software und Service GmbH gesehen?</h5>
				<h5 class="headline mb-4">Demographiedaten: Durchschnittliches Alter der Besucher, Geschlecht, Wohnort, Sprache.</h5>
				<h5 class="headline mb-4">Diese Statistiken, aus denen wir keinen Rückschluss auf individuelle Nutzer ziehen können, verwenden wir dazu, um unser Online-Angebot auf Facebook ständig zu verbessern und auf die Interessen unserer Nutzer besser einzugehen. Wir können die statistischen Daten nicht mit den Profildaten unserer Fans verknüpfen. Sie können über Ihre persönlichen Facebook Einstellungen darüber entscheiden in welcher Form Ihnen zielgerichtete Werbung angezeigt wird.</h5>
				<h5 class="headline mb-4">Die APOFOLIO Software und Service GmbH erhält über Facebook personenbezogenen Daten, wenn Sie uns diese aktiv über eine persönliche Nachricht auf Facebook mitteilen. Wir nutzen Ihre Daten (z.B. Vorname, Name, E-Mail oder Telefonnummer) zur Beantwortung Ihres Anliegens. Eine darüber hinausgehende Speicherung Ihrer Daten findet nicht statt. Die APOFOLIO Software und Service GmbH erhält über Facebook auch personenbezogene Daten, wenn Sie zur Übermittlung der Daten an uns ein Formular mit vorab ausgefüllten Feldern mit Daten aus Ihrem Profil verwenden und die Daten mit Klick auf einen Button aktiv an uns senden.</h5>
				<h5 class="headline mb-4">Die APOFOLIO Software und Service GmbH verwendet Ihre Daten bei der Nutzung der Chat Funktion, um Ihre Anfrage zu beantworten. Die so erhobenen Daten und Informationen dienen der Kontaktaufnahme mit Ihnen, um Ihnen die gewünschten Informationen, Auskünfte und Angebote zu unterbreiten.</h5>
				<h4 class="headline font-weight-medium mb-4">Datenübermittlung an Dritte</h4>
				<h5 class="headline mb-4">Wir behandeln Ihre personenbezogenen Daten vertraulich und übermitteln diese nicht an unbefugte Dritte.</h5>
				<h4 class="headline font-weight-medium mb-4">Auftragsverarbeitung</h4>
				<h5 class="headline mb-4">Im Rahmen des Betriebs dieser Facebook Seite und der damit zusammenhängenden Prozesse können uns weitere Dienstleister unterstützen (z. B. beim Hosting und der Webentwicklung). Diese Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend Artikel 28 Datenschutzgrundverordnung vertraglich verpflichtet.</h5>
				<h4 class="headline font-weight-medium mb-4">Ihre Rechte als Nutzer</h4>
				<h5 class="headline font-weight-medium mb-4">Auskunftsrecht (Art. 15 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden; ist dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im einzelnen aufgeführten Informationen.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Berichtigung und Löschung (Art. 16 und 17 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht, unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</h5>
				<h5 class="headline mb-4">Sie haben zudem das Recht, zu verlangen, dass sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im einzelnen aufgeführten Gründe zutrifft, z. B. wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</h5>
				<h5 class="headline mb-4">Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer einer etwaigen Prüfung.</h5>
				<h5 class="headline font-weight-medium mb-4">Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</h5>
				<h5 class="headline mb-4">In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt werden, haben Sie das Recht, die sie betreffenden personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu verlangen.</h5>
				<h5 class="headline font-weight-medium mb-4">Widerspruchsrecht (Art. 21 DSGVO)</h5>
				<h5 class="headline mb-4">Werden Daten auf Grundlage von Art. 6 Abs. 1 lit. f erhoben (Datenverarbeitung zur Wahrung berechtigter Interessen), steht Ihnen das Recht zu, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende schutzwürdige Gründe für die Verarbeitung vor, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</h5>
				<h4 class="headline font-weight-medium mb-4">Beschwerderecht bei einer Aufsichtsbehörde</h4>
				<h5 class="headline mb-4">Sie haben gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden Daten gegen datenschutzrechtliche Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in dem Mitgliedstaat Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes geltend gemacht werden.</h5>
				<h4 class="headline font-weight-medium mb-4">Kontaktdaten des Datenschutzbeauftragten</h4>
				<h5 class="headline mb-4">Unser Datenschutzbeauftragter steht Ihnen gerne für Auskünfte oder Anregungen zum Thema Datenschutz zur Verfügung:</h5>
				<h5 class="headline">ApoTune Consulting GmbH</h5>
				<h5 class="headline">Häherweg 9</h5>
				<h5 class="headline mb-4">48291 Telgte</h5>
				<h5 class="headline">Ansprechpartner: Dr. Hendrik Hahn</h5>
				<h5 class="headline">Telefon: +49 173 219 21 56</h5>
				<h5 class="headline">E-Mail: hendrik.hahn@apotune-consulting.email</h5>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	
}
</script>