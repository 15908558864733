<template>
	<v-container fluid>
		<v-row class="mb-6" justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/apofolio_logo.png" contain max-height="8vh" position="center right"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6" align="center" justify="center">
				<h4 class="display-1 mb-3 secondary--text">#EFFIZIENT</h4>
				<h4 class="display-1 mb-3 primary--text">#WIRTSCHAFTLICH</h4>
				<h4 class="display-1 mb-3 accent--text">#TRANSPARENT</h4>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/banner.png" contain position="center center"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<h5 class="headline mb-4">Sie als selbständiger Apotheker wissen am Besten, wie aufwändig das Management einer oder auch mehrerer Apotheken sein kann.</h5>
				<h5 class="headline mb-4">Für eine erfolgreiche Apotheke gilt es, viele Faktoren zu beachten:</h5>
				<ul class="headline mb-4">
					<li>Ist unser Einkauf gut strukturiert und erhalten wir die optimalen Einkaufskonditionen?</li>
					<li>Wie entwickelt sich die Apotheke im Vergleich zum Markt und werden alle Potentiale voll ausgeschöpft?</li>
					<li>Werden die Preise markt- und ertragsorientiert kalkuliert?</li>
					<li>Werden alle Möglichkeiten der Digitalisierung genutzt?</li>
				</ul>
				<h5 class="headline mb-4">Unsere Software ist speziell für Ihre Apotheke entwickelt.</h5>
				<h5 class="headline mb-4">Überzeugen Sie sich davon.</h5>
				<h5 class="headline mb-4">Viele Grüße aus Köln</h5>
				<v-img src="../assets/passbild2.jpg" contain max-height="25vh" position="left center"></v-img>
				<h5 class="headline mt-4">Christian Igel</h5>
				<h5 class="headline">Geschäftsführender Gesellschafter</h5>
				<h5 class="headline mb-4">der APOFOLIO GmbH Köln</h5>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	
}
</script>
