<template>
	<v-container fluid>
		<v-row class="mb-12" justify="center">
			<v-col cols="12" lg="9" xl="6">
				<v-img src="../assets/apofolio_logo.png" contain max-height="8vh" position="center right"></v-img>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" lg="9" xl="6">
				<h4 class="display-1 mb-3 primary--text">Angaben gemäß § 5 TMG</h4>
				<h5 class="headline font-weight-medium">APOFOLIO Software und Service GmbH</h5>
				<h5 class="headline">Stieleichenweg 2A</h5>
				<h5 class="headline mb-4">50999 Köln</h5>
				<h5 class="headline">Vertreten durch:</h5>
				<h5 class="headline mb-4">Christian Igel (Geschäftsführender Gesellschafter)</h5>
				<h5 class="headline">Inhaltlich verantwortliche Person für journalistisch-redaktionelle Beiträge:</h5>
				<h5 class="headline mb-4">Christian Igel (Geschäftsführender Gesellschafter)</h5>
				<h4 class="display-1 mb-3 primary--text">Kontakt</h4>
				<h5 class="headline">Telefon: +49 2236 949 36 66</h5>
				<h5 class="headline mb-4">Telefax: +49 2236 949 36 67</h5>
				<h5 class="headline">Mail: post@apofolio.koeln</h5>
				<h5 class="headline mb-4">Web: www.apofolio.koeln</h5>
				<h4 class="display-1 mb-3 primary--text">Registereintrag</h4>
				<h5 class="headline">Eintragung im Handelsregister</h5>
				<h5 class="headline">Registergericht: Amtsgericht Köln</h5>
				<h5 class="headline mb-4">Registernummer: HRB 91982</h5>
				<h4 class="display-1 mb-3 primary--text">Umsatzsteuer</h4>
				<h5 class="headline mb-4">Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE313450821</h5>
				<h4 class="display-1 mb-3 primary--text">Streitschlichtung</h4>
				<h5 class="headline mb-4">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</h5>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	
}
</script>