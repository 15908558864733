<template>
	<v-container fluid>
		<v-row justify="center">
			<v-col cols="4" sm="2" md="2" lg="2" xl="1">
				<v-tabs vertical v-model="tab">
					<v-tab v-for="item in items" :key="item.tab">
						<span class="font-weight-black secondary--text text-lowercase">{{ item.tabTextSecondary }}</span>
						<span class="font-weight-regular primary--text text-capitalize">{{ item.tabTextPrimary }}</span>
					</v-tab>
				</v-tabs>
			</v-col>
			<v-col cols="8" sm="10" md="8" lg="8" xl="6">
				<v-tabs-items v-model="tab">
					<v-tab-item v-for="item in items" :key="item.tab">
						<v-card flat tile>
							<v-card-text>
								<h4 class="headline mb-2">
									<span class="font-weight-black secondary--text">{{ item.tabTextSecondary }}</span>
									<span class="font-weight-regular primary--text">{{ item.tabTextPrimary }}</span>
								</h4>
								<h5 class="body-1 font-italic font-weight-bold mb-4">{{ item.headline }}</h5>
								<v-row>
									<v-col cols="12">
										<v-img :src="bild(item.screen1)" contain max-height="50vh" position="left center"></v-img>
									</v-col>
								</v-row>
								<h5 class="body-1 mb-4">{{ item.text1 }}</h5>
								<ul class="body-1 mb-4" v-if="item.list1 != null">
									<li v-for="listitem in item.list1" :key="listitem">{{ listitem }}</li>
								</ul>
								<v-row>
									<v-col cols="12">
										<v-img :src="bild(item.screen2)" contain max-height="50vh" position="left center"></v-img>
									</v-col>
								</v-row>
								<h5 class="body-1 font-weight-bold mb-4">{{ item.text2 }}</h5>
								<ul class="body-1 mb-4" v-if="item.list2 != null">
									<li v-for="listitem in item.list2" :key="listitem">{{ listitem }}</li>
								</ul>
								<h5 class="body-1 font-weight-bold">Gerne bieten wir Ihnen die Möglichkeit einer Online-Vorführung an.</h5>
								<h5 class="body-1 font-weight-bold mb-4">Vereinbaren Sie einfach mit uns einen Termin unter der Rufnummer +49 2236 949 36 66 oder senden Sie uns eine E-Mail mit Ihrem Terminwunsch an post@apofolio.koeln.</h5>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data () {
		return {
			tab: null,
			items: [
				{ 
					tab: "kuka",
					tabTextPrimary: "Card", 
					tabTextSecondary: "apo",
					headline: "– Der einfachste Weg auf das Smartphone Ihrer Kunden. Und das ganz ohne APP.",
					screen1: "kuka_01",
					text1: "Beste Kundenbindung mit der digitalen Generation. – Also mit buchstäblich jedem, der ein Handy besitzt. Ihre Kundenkarte hat es verdient, digital zu werden.",
					list1: null,
					screen2: "kuka_02",
					text2: "Ihre Vorteile im Überblick",
					list2: [
						"Kundenbindung durch bessere Kommunikation",
						"Immer dabei im Handy",
						"Keine überflüssige App",
						"Coupon und Rabatte",
						"Events und Aktionen",
						"Push-Nachrichten und Informationen",
						"Imagegewinn in Sachen Digitalisierung",
						"Nachhaltig da ressourenfrei"
					]
				},
				{ 
					tab: "analyse",
					tabTextPrimary: "Analyse", 
					tabTextSecondary: "apo",
					headline: "– Ihr webbasiertes Analysetool für alle betriebswirtschaftlichen Fragen.",
					screen1: "analyse01",
					text1: "Mit der Webanwendung apoAnalyse haben Sie online Zugriff auf alle relevanten Informationen Ihrer Apotheke(n). Die Webanwendung ist mit jedem internetfähigen Endgerät nutzbar. Sie umfasst unter Anderem folgende Auswertungstools:",
					list1: [
						"apoCheck – Der virtuelle Apothekenberater",
						"apoStatus – Das Trendbarometer für Ihre Apotheke(n)",
						"Warengruppenanalyse",
						"Betriebswirtschaftliche Auswertungen",
						"Auswertungen auf Artikelebene",
						"Spannenanalyse",
						"Herstellerauswertungen",
						"Kundenfrequenzanalyse",
						"Mitarbeiterauswertungen",
						"Kennzahlensysteme",
						"Warenwirtschaftsanalysen",
						"u.v.m."
					],
					screen2: "analyse02",
					text2: "Ihre Vorteile im Überblick",
					list2: [
						"Interner und externer Betriebsvergleich",
						"Bündelung aller relevanten Daten an einem Ort",
						"Verfügbarkeit der Daten zu jeder Zeit an jedem Ort",
						"Aktuelle Entwicklung und Trends täglich im Blick",
						"Kein Eingabeaufwand und keine Datenpflege",
						"Zeitersparnis bei der Unternehmensführung"
					]
				},
				{ 
					tab: "gh",
					tabTextPrimary: "GH", 
					tabTextSecondary: "apo",
					headline: "– Monatliche Ermittlung der Großhandelskonditionen.",
					screen1: "gh_01",
					text1: "Im Rahmen dieser Leistung wird die Rechnung der beliefernden Großhandungen zur Feststellung der Konditionen nach einem speziellen Verfahren durch uns verarbeitet. Die daraus resultierende Auswertung wird über unsere Webanwendung apoAnalyse zur Verfügung gestellt. Zudem erhalten Sie aktive Hinweise zur Rechnungslegung.",
					list1: null,
					screen2: "gh_02",
					text2: "Ihre Vorteile im Überblick",
					list2: [
						"Interner und externer Konditionsvergleich",
						"Zeitnahe Überprüfung der Kondition",
						"Schnelle Reaktionsmöglichkeit bei Marktänderunegn",
						"Bündelung der Daten aus Warenwirtschaft und Monatsrechnungen",
						"Aktive Hinweise per E-Mail oder Telefon",
						"Aktuelle Entwicklung und Trends im Blick",
						"Kein Eingabeaufwand und keine Datenpflege"
					]
				},
				{ 
					tab: "preis",
					tabTextPrimary: "Preis", 
					tabTextSecondary: "apo",
					headline: "– Preissicherheit durch externen Preisvergleich.",
					screen1: "preis_01",
					text1: "Im Rahmen dieser Leistung werden Sie bei der optimalen Preisfindung für Ihren Apothekenstandort von unserem Kompetenzteam begleitet. Hierzu werden individuelle Preisvorschläge für die frei kalkulierbaren Artikel unterbreitet und mit Ihnen abgestimmt. Bei der Erstellung der Preisvorschläge werden das Marktumfeld, die Marktkennzahlen (GfK-Daten), die bundesweiten Durchschnittspreise auf Artikelebene, Ihre persönlichen Präferenzen und die heutige Preisgestaltung berücksichtigt. Monatlich wird ein Preisupdate erstellt und von uns in Ihre Warenwirtschaft importiert. Zudem werden Sie bei der Preisänderung durch Preisänderungsdienste unterstützt. Alle drei Monate erhalten Sie eine Erfolgskontrolle.",
					list1: null,
					screen2: "preis_02",
					text2: "Ihre Vorteile im Überblick",
					list2: [
						"Ertragsoptimierung durch marktgerechte Preisgestaltung",
						"Optimale Preisfindung durch ABC-Analyse und Berücksichtigung der Preiselastizität",
						"Wirkungsanalyse bei Preisänderungen",
						"Vermeidung von Kalkulationsfehlern",
						"Entlastung des Backoffice",
						"Monatliches Preisupdate",
						"Quartalsweise Erfolgskontrolle"
					]
				}
			]
		}
	},
	methods: {
		bild: function(name) {
			const bild = require.context('../assets/', false, /\.png$/)
			return name ? bild(`./${name}.png`) : ''
		}
	}
}
</script>