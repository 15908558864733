<template>
	<v-app>
		<v-navigation-drawer app dark v-model="drawer" temporary floating width="220px">
			<v-list-item>
				<v-list-item-content>
					<v-list-item-title class="caption">Hauptmenü</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
      		<v-divider></v-divider>
			<v-list dense>
				<v-list-item @click.stop="onSelect('startseite')">
					<v-list-item-icon>
						<v-icon>mdi-home-variant-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Startseite</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click.stop="onSelect('leistungen')">
					<v-list-item-icon>
						<v-icon>mdi-cube-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Lösungen</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click.stop="onSelect('unternehmen')">
					<v-list-item-icon>
						<v-icon>mdi-information-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Unternehmen</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click.stop="onSelect('demo')">
					<v-list-item-icon>
						<v-icon>mdi-link</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Demo-Anwendung</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click.stop="onSelect('impressum')">
					<v-list-item-icon>
						<v-icon>mdi-map-marker-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Impressum</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click.stop="onSelect('datenschutz')">
					<v-list-item-icon>
						<v-icon>mdi-shield-check-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Datenschutz</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
    	</v-navigation-drawer>

		<v-app-bar app dark shrink-on-scroll scroll-target="#scrolling-techniques-2" src="./assets/apofolio_background.jpg" height="280px">
			<template v-slot:img="{ props }">
				<v-img v-bind="props" gradient="to top right, rgba(67,149,82,.6), rgba(43,50,109,.7) "></v-img>
			</template>
			<v-app-bar-nav-icon x-large @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>APOFOLIO Software und Service GmbH Köln</v-toolbar-title>
		</v-app-bar>

		<v-main>
			<v-sheet id="scrolling-techniques-2" class="overflow-y-auto" max-height="calc(100vh - 90px)">
				<router-view></router-view>
			</v-sheet>
    	</v-main>

		<v-footer app dark fixed dense color="primary">
			<div class="pr-3">&copy;2020 — APOFOLIO Software und Service GmbH Köln</div>
    	</v-footer>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data: function() {
		return {
			drawer: null
		}
	},
	created: function() {
		if (this.$workbox) {
			this.$workbox.addEventListener("waiting", () => {
				this.showUpgradeUI = true;
			});
		}
		this.$vuetify.theme.themes.light.primary = "#27387A"
		this.$vuetify.theme.themes.light.secondary = "#439249"
		this.$vuetify.theme.themes.dark.primary = "#27387A"
		this.$vuetify.theme.themes.dark.secondary = "#439249"
	},
	methods: {
		async accept() {
			this.showUpgradeUI = false
			await this.$workbox.messageSW({ type: "SKIP_WAITING" });
		},
		onSelect: function(link) {
			this.drawer = !this.drawer
			if (link == "demo") {
				let myWindow = window.open("http://demo.apofolio.de/index.php?email=demo@apofolio.de&pass=aposoft26826", "_blank");
			} else if (link == "startseite") {
				this.$router.push("/")
			} else {
				this.$router.push(link)	
			}
		}
	}
}
</script>
