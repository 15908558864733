import Vue 				from 'vue'
import VueRouter 		from 'vue-router'
import Startseite 		from '../views/Startseite.vue'
import Leistungen 		from '../views/Leistungen.vue'
import Unternehmen 		from '../views/Unternehmen.vue'
import Impressum 		from '../views/Impressum.vue'
import Datenschutz 		from '../views/Datenschutz.vue'
import Datenschutz_FB 	from '../views/Datenschutz-FB.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Startseite',
		component: Startseite
	},
	{
		path: '/leistungen',
		name: 'Leistungen',
		component: Leistungen
	},
	{
		path: '/unternehmen',
		name: 'Unternehmen',
		component: Unternehmen
	},
	{
		path: '/impressum',
		name: 'Impressum',
		component: Impressum
	},
	{
		path: '/datenschutz',
		name: 'Datenschutz',
		component: Datenschutz
	},
	{
		path: '/datenschutz-fb',
		name: 'Datenschutz-FB',
		component: Datenschutz_FB
	}
]

const router = new VueRouter({
	routes
})

export default router
